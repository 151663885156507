<script setup lang="ts">
import { Button, Divider } from "@/components/Ui";
import { computed } from "vue";
import { onDashboard, onAuth } from "@/helpers/events";
import { type TAuthQuickColor } from "~/components/Auth/types";
const { t } = useI18n();

interface Props {
  google?: boolean;
  apple?: boolean;
  telegram?: boolean;
  facebook?: boolean;
  whatsapp?: boolean;
  titleClass?: string;
  withLogin?: boolean;
  size: "default" | "small";
  color?: TAuthQuickColor;
  googleButtonEvent?: string;
  appleButtonEvent?: string;
  telegramButtonEvent?: string;
  whatsappButtonEvent?: string;
  emailButtonEvent?: string;
}

const props = defineProps<Props>();
const isSafari = ref(false);
const template = computed(() => {
  const authGateway = [
    props.google,
    props.apple,
    props.telegram,
    props.facebook,
    props.whatsapp,
  ].filter((i: boolean) => i);
  return authGateway.length;
});

const onRegisterByGateway = (gateway: string) => {
  if (gateway === "email") {
    onDashboard(
      props.emailButtonEvent || "create account started",
      "/register",
      {},
      {},
      true,
    );
  }
  if (gateway === "google") {
    onAuth(props.googleButtonEvent || "account info submitted", gateway, {});
  }
  if (gateway === "apple") {
    onAuth(props.appleButtonEvent || "account info submitted", gateway, {});
  }
  if (gateway === "telegram") {
    onAuth(props.telegramButtonEvent || "account info submitted", gateway, {});
  }
  if (gateway === "whatsapp") {
    onAuth(props.whatsappButtonEvent || "account info submitted", gateway, {});
  }
};
onMounted(() => {
  isSafari.value =
    navigator.userAgent.search("Safari") >= 0 &&
    navigator.userAgent.search("Chrome") < 0;
});
</script>
<template>
  <div :class="`${[auth.root]}  ${[auth[`root-${props.size}`]]}`">
    <div
      v-if="$slots.title"
      :class="props.titleClass ? titleClass : auth.title"
    >
      <slot name="title" />
    </div>
    <div :class="`${[auth.buttons]} ${[auth[`template-${template}`]]}`">
      <Button
        v-if="props.google"
        icon="google-color"
        :title="!isSafari ? `${t('auth.continue')} Google` : ''"
        :color="props.color || 'light-grey'"
        :class="[auth.button, auth[`buttons-${isSafari ? 'second' : 'first'}`]]"
        @click="onRegisterByGateway('google')"
      />
      <Button
        v-if="props.apple"
        icon="apple"
        :title="template === 2 || isSafari ? `${t('auth.continue')} Apple` : ''"
        :color="props.color || 'light-grey'"
        :class="[auth.button, auth[`buttons-${isSafari ? 'first' : 'second'}`]]"
        @click="onRegisterByGateway('apple')"
      />
      <Button
        v-if="props.telegram"
        icon="telegram-color"
        :title="template === 2 ? `${t('auth.continue')} Telegram` : ''"
        :color="props.color || 'light-grey'"
        :class="auth.button"
        @click="onRegisterByGateway('telegram')"
      />
      <Button
        v-if="props.facebook"
        icon="facebook-circle"
        :title="template === 2 ? `${t('auth.continue')} Facebook` : ''"
        :color="props.color || 'light-grey'"
        :class="auth.button"
        @click="onRegisterByGateway('facebook')"
      />
      <Button
        v-if="props.whatsapp"
        icon="whatsapp-color"
        :title="template === 2 ? `${t('auth.continue')} Whatsapp` : ''"
        :color="props.color || 'light-grey'"
        :class="auth.button"
        @click="onRegisterByGateway('whatsapp')"
      />
    </div>
    <Divider
      :label="t('auth.or')"
      type="dashed"
      :class="[
        auth[`divider-${props.size}`],
        auth[`divider-${props.color || 'light-grey'}`],
      ]"
    />
    <div class="flex flex-col gap-4">
      <Button
        :title="`${t('auth.continue')} email`"
        :color="props.color"
        :class="auth.button"
        @click="onRegisterByGateway('email')"
      />
      <div
        v-if="props.withLogin"
        :class="auth.login"
      >
        {{ t("auth.have_acc") }}
        <span
          @click="
            onDashboard(
              'login_button_click',
              '/login',
              {
                'cta type': 'button',
                'cta location': 'quick auth',
                'cta text': 'Sign in',
              },
              {},
              true,
            )
          "
        >
          {{ t("auth.signin") }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module="auth">
.root {
  @apply flex flex-col;

  &-default {
    @apply gap-8;
  }

  &-small {
    @apply gap-3;
  }
}

.button {
  @apply text-[15px] p-2 w-full h-[56px];

  & svg {
    @apply w-8 h-8 min-w-[32px];
  }
}

.buttons {
  @apply grid gap-x-2 gap-y-[10px];

  &-first {
    @apply order-1 col-span-3;
  }
  &-second {
    @apply order-2 col-span-1;
  }
  & > button:nth-child(n + 3) {
    @apply order-3;
  }
}

.template {
  &-1 {
    @apply grid-cols-1;
  }

  &-2 {
    @apply grid-cols-1;
    & button {
      @apply col-span-1;
    }
  }

  &-3 {
    @apply grid-cols-2;
    & button {
      &:first-child {
        @apply col-span-2;
      }

      &:nth-child(n + 2) {
        @apply col-span-1;
      }
    }
  }

  &-4 {
    @apply grid-cols-3;
    & button {
      //&:first-child {
      //  @apply col-span-3;
      //}

      &:nth-child(n + 3) {
        @apply col-span-1;
      }
    }
  }

  &-5 {
    @apply grid-cols-4;
    & button {
      &:first-child {
        @apply col-span-4;
      }

      &:nth-child(n + 2) {
        @apply col-span-2 xxs:col-span-1;
      }
    }
  }
}

.title {
  @apply font-semibold text-xl text-neutral-800;
}

.login {
  @apply inline-flex flex-col xs:flex-row items-center gap-x-2 mx-auto text-[15px] text-neutral-600;

  & > span {
    @apply text-neutral-900 font-semibold cursor-pointer;
  }
}

.divider {
  &-default {
    @apply text-[16px];
  }

  &-small {
    @apply text-sm;
  }

  &-grey,
  &-light-grey {
  }

  &-dark-grey {
    @apply text-white;
  }
}
</style>

